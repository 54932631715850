import React, { useState, useEffect } from 'react';

import TextInput from '../TextInput';
import ValidationError from '../ValidationError';

import './style.scss';

const SelectInput = ({ id, name, label, value, updateValue, options, errors = [] }) => {
  const OTHER_VALUE_TRIGGER = 'specify';
  const OWN_BRAND_TRIGGER = 'own_brand';

  const [isOtherValue, setIsOtherValue] = useState(false);
  const [isOwnBrand, setIsOwnBrand] = useState(false);
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setIsOtherValue(selectedValue === OTHER_VALUE_TRIGGER);
    setIsOwnBrand(selectedValue === OWN_BRAND_TRIGGER);
    updateValue(selectedValue === OTHER_VALUE_TRIGGER || selectedValue === OWN_BRAND_TRIGGER ? '' : selectedValue);
  };

  useEffect(() => {
    if (value === OTHER_VALUE_TRIGGER) {
      setIsOtherValue(true);
    } else if (value === OWN_BRAND_TRIGGER) {
      setIsOwnBrand(true);
    }
  }, [value]);

  return (
    <div className="select-input">
      {label ? <label htmlFor={id}>{label}</label> : null}
      <select id={id} value={isOtherValue ? OTHER_VALUE_TRIGGER : isOwnBrand ? OWN_BRAND_TRIGGER : value} onChange={handleSelectChange}>
        {options.map((o) => (
          <option value={o.value}>{o.label}</option>
        ))}
      </select>
      {(isOtherValue || isOwnBrand) && (
        <TextInput
          id={`${id}_${isOtherValue ? 'specify' : 'own_brand'}`}
          name={`${name}_${isOtherValue ? 'specify' : 'own_brand'}`}
          updateValue={updateValue}
        />
      )}
      {errors.length ? <ValidationError errorMessage={errors[0]} /> : null}
    </div>
  );
};

export default SelectInput;