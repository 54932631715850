import React, {useState, useEffect}from 'react';
import { PhoneInput, removeDialCode } from 'react-international-phone';
import 'react-international-phone/style.css';

import ValidationError from '../ValidationError';

import './styles.scss';

const PhoneLocalInput = ({ value, onChange, placeholder, errors = [] }) => {

  return (
    <div className="phone-input">
      <PhoneInput
        defaultCountry={'it'}
        value={value}
        name={'mobile_no'}
        onChange={(phone, meta) => {
          onChange(phone, meta);
        }}
        placeholder={placeholder}
        inputProps={{
          id: 'mobile_no', 
          className: `react-international-phone-input ${errors.length ? 'input-error' : ''}`
        }}
      />
      {errors.length ? <ValidationError errorMessage={errors[0]} /> : null}
    </div>
  );
};

export default PhoneLocalInput;
