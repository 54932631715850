import React from 'react';

import ValidationError from '../ValidationError';

import './style.scss';

const TextInput = ({ id, name, placeholder, value, updateValue, errors = [], forwardedRef,required }) => {
  return (
    <div className="text-input">
      <input
        type="text"
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        ref={forwardedRef}
        onChange={(e) => updateValue(e.target.value)}
        required={required}
        className={errors.length ? 'input-error' : ''}
      />
      {errors.length ? <ValidationError errorMessage={errors[0]} /> : null}
    </div>
  );
};

export default TextInput;