import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link,useLocation, useNavigate} from 'react-router-dom';

import useUserPermissions from '../../hooks/getUserPermissions';

import DashboardAgent from './DashboardAgent';
import CreditSituationList from './components/CreditSituationList/CreditSituationList';
import DocumentsList from './components/DocumentsList/DocumentsList';
import OrderList from './components/OrderList';
import SubUsersList from './components/SubUsersList';
import TicketsList from './components/TicketsList';

const User_Dashboards = () => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);
  console.log(dataUser);
  const [tab, setTab] = useState('Ordini');
  const { t } = useTranslation();
  const { userPermissions } = useUserPermissions({});

  const location = useLocation();
  const navigate = useNavigate();
 
  const handleTabClick = (newTab) => {
    setTab(newTab);
    navigate(`?tab=${newTab}`); // Aggiungiamo il parametro all'URL
  };


  const Logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userDataDetail');
    localStorage.removeItem('agentDataDetail');
    window.location.href = '/';
  };

  useEffect(() => {
    document.getElementById('home_page_class').classList.remove('home_page');
    const queryParams = new URLSearchParams(location.search);
    const valuetab = queryParams.get('tab');

    if (valuetab) {
        setTab(valuetab);
        queryParams.delete('tab');
    } else {
        setTab('Ordini');
    }
  }, [tab === 'Utenti',location.search]);

  if (dataUser?.is_agent) {
    return <DashboardAgent />;
  }
  return (
    <>
      <div className="main_content order_page profile_page" style={{ paddingBottom: 100 }}>
        <div className="order_section_inner container">
          <div className="order_title">
            <h1>{t('Dashboard utente')}</h1>
            <button onClick={() => Logout()}>{t('Logout')}</button>
          </div>
          <div className="order_detail_card">
            <div className="title_section">
              {parseInt(dataUser?.parent_id) == 0 ? (
                <>
                  <h3>{t('Dati personali')}</h3>
                  <Link to={'/profile-manage/' + dataUser.id}>{t('Richiedi modifica')}</Link>
                </>
              ) : (
                ''
              )}
            </div>

            {parseInt(dataUser?.parent_id) == 0 ? (
              <div className="details_section">
                <div className="row_field details">
                  <div className="data">
                    <label>{t('Ragione sociale')}</label>
                    <strong>{dataUser?.business_name}</strong>
                  </div>
                  <div className="data">
                    <label>{t('P.IVA')}</label>
                    <strong>{dataUser?.vat_number}</strong>
                  </div>
                  <div className="data">
                    <label>{t('Email')}</label>
                    <strong>{dataUser?.email}</strong>
                  </div>
                  <div className="data">
                    <label>{t('Telefono')}</label>
                    <strong>{dataUser?.mobile_no}</strong>
                  </div>
                </div>
                <div className="row_field main_address">
                  <div className="data">
                    <label>{t('Indirizzo principale')}</label>
                    <strong>{dataUser?.address}</strong>
                  </div>
                </div>
                <div className="row_field sec_address">
                  <div className="data">
                    <label>{t('Indirizzi secondari')}</label>
                    {/*<strong className="first">
                                        {dataUser?.address} {dataUser?.city}
                                    </strong>
                                    <strong>{dataUser?.village} {dataUser?.province}</strong>*/}
                  </div>
                </div>
              </div>
            ) : (
              <div className="details_section">
                <div className="row_field details">
                  <div className="data">
                    <label>{t('Nome')}</label>
                    <strong>{dataUser?.fname} </strong>
                  </div>
                  <div className="data">
                    <label>{t('Cognome')}</label>
                    <strong>{dataUser?.lname}</strong>
                  </div>
                  <div className="data">
                    <label>Email</label>
                    <strong>{dataUser?.email}</strong>
                  </div>
                  <div className="data">
                    <label>{t('Telefono')}</label>
                    <strong>{dataUser?.phone}</strong>
                  </div>
                  <div className="data">
                    <label>{t('Ruolo')}</label>
                    <strong>{dataUser?.role}</strong>
                  </div>
                  {dataUser?.parent_name ? (
                    <>
                      <div className="data">
                        <label>{t('Utente Master')}</label>
                        <strong>{dataUser?.parent_username}</strong>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="row_field login_data">
                  <div className="data sub_title">
                    <h3>{t('Dati di accesso')}</h3>
                  </div>
                  <div className="data">
                    <label>Username</label>
                    <strong>{dataUser?.username}</strong>
                  </div>
                  <div className="data">
                    <label>Password</label>
                    <strong>**************</strong>
                  </div>
                </div>
                <div className="row_field catalog_data">
                  <div className="data sub_title">
                    <h3>{t('Privilegi situazione creditizia')}</h3>
                  </div>
                  <div className="data sub_content">
                    {dataUser?.view_credit ? (
                      <img className="img20" src="images/utenti-true.png" alt="Checkmark" />
                    ) : (
                      <img className="img20" src="images/utenti-false.png" alt="Checkmark" />
                    )}
                    <span>{t('Visualizza situazione creditizia')}</span>
                  </div>
                </div>
                <div className="row_field order_data">
                  <div className="data sub_title">
                    <h3>{t('Privilegi ordini')}</h3>
                  </div>
                  <div className="data sub_content">
                    {dataUser?.create_order ? (
                      <img className="img20" src="images/utenti-true.png" alt="Checkmark" />
                    ) : (
                      <img className="img20" src="images/utenti-false.png" alt="Checkmark" />
                    )}
                    <span>{t('Creazione nuovo ordine')}</span>
                  </div>
                  <div className="data sub_content">
                    {dataUser?.order_history ? (
                      <img className="img20" src="images/utenti-true.png" alt="Checkmark" />
                    ) : (
                      <img className="img20" src="images/utenti-false.png" alt="Checkmark" />
                    )}
                    <span>Visualizzazione storico ordini</span>
                  </div>
                  <div className="data sub_content">
                    {dataUser?.download_document ? (
                      <img className="img20" src="images/utenti-true.png" alt="Checkmark" />
                    ) : (
                      <img className="img20" src="images/utenti-false.png" alt="Checkmark" />
                    )}
                    <span>{t('Download documentazione')}</span>
                  </div>
                </div>
                <div className="row_field ticket_data">
                  <div className="img20 data sub_title">
                    <h3>{t('Privilegi ticket')}</h3>
                  </div>
                  <div className="data sub_content">
                    {dataUser?.create_ticket ? (
                      <img className="img20" src="images/utenti-true.png" alt="Checkmark" />
                    ) : (
                      <img className="img20" src="images/utenti-false.png" alt="Checkmark" />
                    )}
                    <span>{t('Creazione nuovo ticket')}</span>
                  </div>
                  <div className="data sub_content">
                    {dataUser?.ticket_history ? (
                      <img className="img20" src="images/utenti-true.png" alt="Checkmark" />
                    ) : (
                      <img className="img20" src="images/utenti-false.png" alt="Checkmark" />
                    )}
                    <span>{t('Visualizzazione storico ticket')}</span>
                  </div>
                </div>
              </div>
            )}
          </div>

          {userPermissions.canViewDashboardTabs ? (
            <div className="order_tab">
              {dataUser?.order_history && (
                <>
                  <span onClick={() => handleTabClick('Ordini')} className={`tab_title ${tab === 'Ordini' ? 'active' : ''}`}>
                    {t('Ordini')}
                  </span>
                  <div className="tab_content order">
                    <div className="content_info">
                      <OrderList />
                    </div>
                  </div>
                </>
              )}
              {dataUser?.CGKWEBPORTALCUSTOMER ==="S\u00ec" && (
                <>
                <span onClick={() => handleTabClick('Documenti')} className={`tab_title ${tab === 'Documenti' ? 'active' : ''}`}>
                  {t('Documenti')}
                </span>
                <div className="tab_content order">
                  <div className="content_info">
                    <DocumentsList />
                  </div>
                </div>
                </>
              )}

              {dataUser?.parent_id === 0 && (
                <>
                  <span onClick={() => handleTabClick('Utenti')} className={`tab_title ${tab === 'Utenti' ? 'active' : ''}`}>
                    {t('Utenti')}
                  </span>
                  <SubUsersList />
                </>
              )}

              {dataUser?.ticket_history && (
                <>
                  <span onClick={() => handleTabClick('Ticket')} className={`tab_title ${tab === 'Ticket' ? 'active' : ''}`}>
                    {t('Ticket')}
                  </span>
                  <div className="tab_content order">
                    <div className="content_info">
                      <TicketsList />
                    </div>
                  </div>
                </>
              )}
              {dataUser?.view_credit && (
                <>
                  <span
                    onClick={() => handleTabClick('Situazione creditizia')}
                    className={`tab_title ${tab === 'Situazione creditizia' ? 'active' : ''}`}
                  >
                    {t('Situazione creditizia')}
                  </span>
                  <div className="tab_content order">
                    <div className="content_info">
                      <CreditSituationList />
                    </div>
                  </div>
                </>
              )}
              
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default User_Dashboards;

{
  /* {dataUser?.download_document && (
              <>
                <span onClick={() => setTab('Fatture')} className={`tab_title ${tab === 'Fatture' ? 'active' : ''}`}>
                  {t('Fatture')}
                </span>
                <div className="tab_content fatture">
                  <div className="content_info">
                    <div className="content_top">
                      <div className="left_data">
                        <div className="search_field">
                          <input type="search" name="search" placeholder="search_order" />
                        </div>
                        <div className="user_field">
                          <div className="user_dropdown">
                            <a href="javascript:void(0);" className="user_current active">
                              Utente
                            </a>
                            <div className="user_options">
                              <ul>
                                <li>
                                  <a href="javascript:void(0);" value="{10}" className="active">
                                    Utente
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" value="{25}">
                                    Utente 1
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" value="{50}">
                                    Utente 2
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="state_field">
                          <div className="state_dropdown">
                            <a href="javascript:void(0);" className="state_current active">
                              Stato
                            </a>
                            <div className="state_options">
                              <ul>
                                <li>
                                  <a href="javascript:void(0);" value="{10}" className="active">
                                    Stato
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" value="{25}">
                                    Stato 1
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" value="{50}">
                                    Stato 2
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="right_data">
                        <div className="number_field">
                          <span>Numero ordini</span>
                          <div className="number_dropdown">
                            <a href="javascript:void(0);" className="number_current active">
                              10
                            </a>
                            <div className="number_options">
                              <ul>
                                <li>
                                  <a href="javascript:void(0);" value="{10}" className="active">
                                    10
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" value="{25}">
                                    20
                                  </a>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" value="{50}">
                                    30
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="content_data">
                      <div className="futture_table_header">
                        <div className="futture_table_row">
                          <div className="futture_table_title">Fattura</div>
                          <div className="futture_table_title">Data</div>
                          <div className="futture_table_title" />
                        </div>
                      </div>
                      <div className="futture_table_body">
                        <div className="futture_table_row">
                          <div className="futture_table_column" data-label="Fattura">
                            <a href="">Fattura N°0000-AA-0000000</a>
                          </div>
                          <div className="futture_table_column" data-label="Data">
                            30/09/2022
                          </div>
                          {dataUser?.download_document && (
                            <div className="futture_table_column download">
                              <a href="#">
                                <span>Download</span>
                                <img src="/images/download-icon.png" alt="Download" />
                              </a>
                            </div>
                          )}
                        </div>
                        <div className="futture_table_row">
                          <div className="futture_table_column" data-label="Fattura">
                            <a href="">Fattura N°0000-AA-0000000</a>
                          </div>
                          <div className="futture_table_column" data-label="Data">
                            15/09/2022
                          </div>
                          {dataUser?.download_document && (
                            <div className="futture_table_column download">
                              <a href="#">
                                <span>Download</span>
                                <img src="/images/download-icon.png" alt="Download" />
                              </a>
                            </div>
                          )}
                        </div>
                        <div className="futture_table_row">
                          <div className="futture_table_column" data-label="Fattura">
                            <a href="">Fattura N°0000-AA-0000000</a>
                          </div>
                          <div className="futture_table_column" data-label="Data">
                            01/09/2022
                          </div>
                          {dataUser?.download_document && (
                            <div className="futture_table_column download">
                              <a href="#">
                                <span>Download</span>
                                <img src="/images/download-icon.png" alt="Download" />
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="pagination_section">
                        <div className="pagination_item">
                          <ul>
                            <li className="prev disable">
                              <a href="#" />
                            </li>
                            <li className="active">
                              <a href="#">1</a>
                            </li>
                            <li>
                              <a href="#">2</a>
                            </li>
                            <li>
                              <a href="#">3</a>
                            </li>
                            <li>
                              <a href="#">4</a>
                            </li>
                            <li className="next">
                              <a href="#" />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )} */
}
{
  /* {(dataUser?.ticket_history || dataUser?.create_ticket) && (
              <>
                <span onClick={() => setTab('Ticket')} className={`tab_title ${tab === 'Ticket' ? 'active' : ''}`}>
                  Ticket{' '}
                </span>
                <div className="tab_content ticket">
                  <div className="content_info">
                    <div className="content_top">
                      <div className="left_data"></div>
                      {dataUser?.create_ticket && (
                        <div className="right_data">
                          <div className="create-user">
                            <a href={"/ticket-create/" + dataUser.id}>{t('create_ticket')}</a>
                          </div>
                        </div>
                      )}
                    </div>
                    {dataUser?.ticket_history && (
                      <>
                        <div className="content_data">
                          <div className="ticket_table_header">
                            <div className="ticket_table_row">
                              <div className="ticket_table_title number">Numero ticket</div>
                              <div className="ticket_table_title">Data</div>
                              <div className="ticket_table_title">Utente</div>
                              <div className="ticket_table_title">Numero ordine</div>
                              <div className="ticket_table_title stato">Stato</div>
                            </div>
                          </div>
                          <div className="ticket_table_body">
                            {ticketData.length > 0 &&
                              ticketData.map((item, index) => {
                                let className = 'ticket_table_column' + ' stato ' + item?.state;
                                return (
                                  <div key={index} className="ticket_table_row">
                                    <div className="ticket_table_column number" data-label="Numero ticket">
                                      <a>#{item?.ticket_number}</a>
                                    </div>
                                    <div className="ticket_table_column" data-label="Data">
                                      {item?.date}
                                    </div>
                                    <div className="ticket_table_column" data-label="Utente">
                                      {item?.user}
                                    </div>
                                    <div className="ticket_table_column" data-label="Numero ordine">
                                      #{item?.order_number}
                                    </div>
                                    <div className={className} data-label="Stato">
                                      {getTranslatedState(item?.state)}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )} */
}
