import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import axios from 'axios';
import useDebounce from './useDebounce';

export const useUserTicketList = ({}) => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);
  const [perPage, setPerPage] = useState(10);

  const filtersInitialState = {
    ticketId: '',
    status: '',
    startDate: '',
    endDate: '',
  };
  const [filters, setFilters] = useState(filtersInitialState);

  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const debouncedFilters = useDebounce(filters, 300);

  const resetFilter = () => {
    setFilters(filtersInitialState);
    setPage(1);
  };

  const updateFilter = (filter, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [filter]: value,
    }));
    setPage(1);
  };

  const updatePerPage = (perPage) => {
    setPerPage(perPage);
    setPage(1);
  };

  useEffect(() => {
    const { ticketId, status, startDate, endDate } = debouncedFilters;
    const abortController = new AbortController();
    const signal = abortController.signal;

    setIsLoading(true);
    //Da attivare nel caso in cui al subuser si voglia far visualizzare i ticket del master
    //const userId = dataUser.parent_id ? dataUser.parent_id : dataUser.id
    axios
      .post(
        '/v1/get-customer-tickets/' + dataUser.id,
        {
          customerid: dataUser.ACCOUNTNUM,
          ticketsDateStart: startDate,
          ticketsDateEnd: endDate,
          ticketsStatus: status,
          ticketId: ticketId,
          fetch: perPage,
          offset: (page - 1) * perPage,
        },
        {
          headers: {
            Authorization: `Bearer ${dataUser.token}`,
          },
          signal,
        }
      )
      .then((res) => {
        setRows(res?.data?.result?.data?.payload?.data || []);
        setTotalPages(1000);
      })
      .catch((error) => {
        console.error('error', error);
        setRows([]);
        setTotalPages(0);
      })
      .finally(() => setIsLoading(false));

    return () => abortController.abort();
  }, [debouncedFilters, perPage, page]);

  return {
    isLoading,
    rows,
    page,
    setPage,
    filters,
    updateFilter,
    totalPages,
    perPage,
    setPerPage,
    resetFilter,
    updatePerPage,
  };
};