import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useSearchParams, useParams } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import axios from 'axios';

import { useLoader } from '../../utils/hooks';
import LangLink from '../navigation/LangLink';

const News = (props) => {
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [category, SetCategory] = useState([]);
  const [years, setYears] = useState([]);
  const [news, SetNews] = useState([]);
  const [category_id, SetCategoryId] = useState(null);
  const [year, SetYear] = useState();
  const [page, SetPage] = useState(0);
  const { t, i18n } = useTranslation();
  const loader = useLoader({ height: 44 });
  const loaderCtn = useLoader();

  const news_list = [
    1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, 37, 40, 43, 46, 49, 52, 55, 58, 61, 64, 67, 70, 73, 76, 79, 82, 85, 88,
    91, 94, 97, 100, 103, 106, 109, 112, 115, 118, 121, 124, 127, 130, 133, 136, 139, 142, 145, 148, 151, 154, 157, 160,
    163, 166, 169, 172, 175, 178, 181, 184, 187, 190, 193, 196, 199,
  ];

  useEffect(() => {
    if (page == 1) {
      document.getElementById('home_page_class').classList.remove('home_page');
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    loaderCtn.setStartLoading();
    loaderCtn.watchPromise(
      axios
        .get('/v1/blog-filters')
        .then((response) => {
          SetCategory(response?.data?.result?.data?.available_categories || []);
          setYears(response?.data?.result?.data?.available_years || []);

          SetYear(response?.data?.result?.data?.available_years?.[0] || null);
        })
        .finally(() => setFiltersLoaded(true))
    );
  }, []);

  useEffect(() => {
    if (!filtersLoaded) {
      return;
    }

    loadNews(false);
  }, [filtersLoaded, category_id, year]);

  const loadNews = (lodemore) => {
    if (lodemore) {
      loader.setStartLoading();
    }

    axios
      .get('/v1/blog', {
        params: { page: page + 1, category_id: category_id, year: year },
      })
      .then((response) => {
        if (lodemore === true) {
          SetNews([...news, ...response.data.result.data.data]);
          SetPage(page + 1);
        } else {
          SetNews([...response.data.result.data.data]);
          SetPage(1);
        }
      })
      .finally((_) => {
        loader.setEndLoading();
      });
  };
  return (
    <div className="main_content blog_page">
      <div className="container title_section">
        <div className="main_title">
          <div className="title blog-title">
            <h2>{t('tutte_le_novita_dal')}</h2>
          </div>
        </div>
        <div className="scopri_anche_section">
          <div className="scopri_anche_item">
            {category.map((category, index) => (
              <div
                key={category.id}
                onClick={(e) => {
                  e.preventDefault();
                  SetPage(0);
                  SetCategoryId(category.id);
                }}
                className={`scopri_anche_box  ${category_id == category.id ? 'active' : ''}`}
              >
                <a key={category.id + 'l'} href="javascript:void(0);">
                  {category.name}
                </a>
              </div>
            ))}
          </div>
          <div className="year_dropdown">
            <select
              class="form-select form-select-lg mb-3"
              defaultValue={year}
              onChange={(event) => {
                SetYear(event.target.value);
                SetPage(0);
              }}
            >
              {years.map((y) => (
                <option value={y}>{y}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {loaderCtn.asJsx || (
        <div className="container blog_wrapper">
          {news.map((news, index) => (
            <div className={news_list.includes(index) ? 'news_item' : 'news_item'}>
              <LangLink className="news_wrapper" to={'/news/' + news.slug}>
                <img src={news.blog_base_image_url} alt="Digital" />
                <div className="news_data news_data_blog">
                  <p className="tag tal ">
                    <span className="date ">
                      <Moment format="D MMM YYYY">{news.blog_date}</Moment>
                    </span>
                    <span> | {news?.category?.name}</span>
                  </p>
                  <p className="name">{news.title}</p>
                  <p className="subtitle">{news.subtitle} </p>
                </div>
              </LangLink>
            </div>
          ))}
        </div>
      )}
      <div className="container more_section">
        <div className="link">
          {loader.asJsx || (
            <button
              onClick={(e) => {
                loadNews(true);
              }}
            >
              {t('mostra_altre_news')}{' '}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default News;