import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const LoggedInAsSubClient = () => {
  const dispatch = useDispatch();
  const userDataDetail = useSelector((state) => state?.auth?.userDataDetail);
  
  const { t } = useTranslation();

  return (
    <div className="logged-in-as-client-status">
      <span>
        {console.log(userDataDetail)}
        {t('subuser.master')} <u>{userDataDetail?.parent_username}</u>
      </span>
      <div className="logged-in-as-client-status__actions">
      </div>
    </div>
  );
};

export default LoggedInAsSubClient;