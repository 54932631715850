import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Slider from 'react-slick';

import axios from 'axios';
import _ from 'lodash';
import 'slick-carousel/slick/slick.css';

import { UPDATE_PRODUCTS_IN_CART_COUNTER } from '../../constants/ActionTypes';
import useUserPermissions from '../../hooks/getUserPermissions';
import { useProduct } from '../../hooks/useProduct';
import { useProductTotals } from '../../hooks/useProductTotals';
import { useModal } from '../../providers/ModalProvider';
import { registerPaths } from '../../providers/TranslatedSlugProvider';
import { handleMultiCartTypeError } from '../../utils/errorHandling';
import { formatQuantityForView, formatPriceForView as localFormatPrice } from '../../utils/format';
import { useLoader } from '../../utils/hooks';
import { getTranslated } from '../../utils/translations';
import User from '../../utils/user';
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton';
import QuantitySelector from '../Inputs/QuantitySelector/QuantitySelector';
import Drawer from '../layout/Drawer/Drawer';

import AvailabilitySelector from './Components/AvailabilitySelector';
import CertificateSelector from './Components/CertificateSelector';
import ProductImage from './Components/ProductImage';
import ProductPanel from './Components/ProductPanel';
import Customerservice from './Customerservice';
import RequestSample from './RequestSample/RequestSample';
import Suggestedslider from './Suggestedslider';

// export const productSearchParamsMap = {
//   id: { en: 'id', it: 'articole_id' },
//   configid: { en: 'configid', it: 'configureIdIt' },
// };
const screenAlias = window.screen;
const MIN_QUANTITY_TO_ORDER = 1;
const hexToDegrees = (hex) => {
  // Convert the hexadecimal color value to RGB values
  const r = parseInt(hex.substring(1, 3), 16) / 255;
  const g = parseInt(hex.substring(3, 5), 16) / 255;
  const b = parseInt(hex.substring(5, 7), 16) / 255;

  // Find the maximum and minimum RGB values
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  // Calculate the hue based on the maximum and minimum RGB values
  let hue = 0;

  if (max === min) {
    hue = 0;
  } else if (max === r) {
    hue = ((g - b) / (max - min)) % 6;
  } else if (max === g) {
    hue = (b - r) / (max - min) + 2;
  } else {
    hue = (r - g) / (max - min) + 4;
  }

  // Convert the hue to a degree value between 0 and 360
  hue = hue * 60;

  if (hue < 0) {
    hue = hue + 360;
  }

  return hue;
};

const Product = (props) => {
  const { userPermissions } = useUserPermissions({});

  // useTranslateAndReplaceUrlWhenLanguageChanged(props, productSearchParamsMap);
  const [tab, setTab] = useState(0);
  const [filterColor, setFilterColor] = useState([]);
  const [activeColor, setActiveColor] = useState('');
  const [colorname, SetColorname] = useState('');
  const [activedetails, setActivedetails] = useState(0);
  const [isZoom, setIsZoom] = useState('');
  const [activeimage, setImage] = useState('');
  const [cID, setCid] = useState(0);
  const [summary, SetSummary] = useState(0);
  const [QTY, _SetQTY] = useState(1);
  const [precise, Setprecise] = useState(0);
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const userDataDetail = useSelector((state) => state?.auth?.userDataDetail);
  const agentDataDetail = useSelector((state) => state?.auth?.agentDataDetail);
  const quantityLoader = useLoader({ height: 82 }, false);
  const params = useParams();

  const dispatch = useDispatch();

  const [showRequestSample, setShowRequestSample] = useState(false);

  const {
    isLoading,
    mainProduct: details,
    setMainProduct: setDetails,
    subProduct,
    setSubProduct,
    isLoadingFilter,
    colors: colorList,
  } = useProduct({
    id: params.productId,
    slug: params.family,
  });

  const {
    price,
    unitPrice,
    totalPrice,
    totalSupplement,
    totalSupplement2,
    isAvailableJson: is_available_json,
    setIsAvailableJson: SetIs_available_json,
    globalAvailable,
    available,
    setAvailable: Setavailable,
    isOption: isoption,
    globalCertifications,
    certification,
    setCertification,
    supplement2,
    isLoading: loadingProductTotal,
  } = useProductTotals({
    mainProduct: details,
    colorName: colorname,
    qty: QTY,
  });

  const formatPriceForView = (amount) => localFormatPrice(amount, User.getCurrency(userDataDetail));

  const SetQTY = (q) => {
    if (q < 0) q = MIN_QUANTITY_TO_ORDER;
    _SetQTY(q);
  };

  /*if (userDataDetail?.view_credit === false) {
    window.location.href = '/';
  }*/

  const handleColorSelect = (color, Name, image, id) => {
    if (id == cID) {
      setImage('');
      setCid(0);
      setActiveColor('');
      SetColorname('');
    } else {
      setImage(image);
      setCid(id);
      setActiveColor(color);
      SetColorname(Name);
    }
  };

  const Richiedi_rocche_preciseChange = () => {
    if (precise == 0) {
      Setprecise(1);
    } else {
      Setprecise(0);
    }
  };

  const closeminicaet = () => {
    SetSummary(0);
    SetQTY(MIN_QUANTITY_TO_ORDER);
    Setprecise(0);
    SetIs_available_json([]);
  };

  const colorDisponibili = (e) => {
    e.stopPropagation();
    document.getElementById('accordion_info_color').classList.toggle('active');
  };

  const handleAccordation = (id, id_next, is_open = 'close') => {
    if (id == 'accordation_1') {
      let accordation_12 = document?.getElementById('accordation_12');
      accordation_12.classList.toggle('header_stiky');
    }

    let component = document?.getElementById(id);
    var acc = document.getElementById(id_next);

    if (is_open == 'open') {
      acc.classList.add('arrow_rottate');
      component.style.maxHeight = component.scrollHeight + 500 + 'px';
    } else {
      acc.classList.toggle('arrow_rottate');
      acc.style.maxHeight = 500 + 'px';

      if (component.style.maxHeight) {
        component.style.maxHeight = null;
      } else {
        component.style.maxHeight = component.scrollHeight + 50 + 'px';
      }
    }
  };
  const modal = useModal();

  const openMiniCart = () => {
    if (cID === 0) {
      modal.showAttention({ title: t('Si prega di selezionare il colore'), confirmButtonText: 'OK' });
    } else {
      SetSummary(cID);
    }
  };

  const addtoCart = () => {
    // Swal.fire({
    //   title: 'Adding to Cart...',
    //   text: 'Please wait while we add the product to your cart',
    //   allowOutsideClick: false,
    //   onBeforeOpen: () => {
    //     Swal.showLoading();
    //   },
    //   showCancelButton: false,
    //   showConfirmButton: false,
    // });

    modal.showLoading({
      // title: 'Aggiungo al carrello...',
      title: t('popup.reorderAdding'), //'Adding product to the cart',
      // subtitle:  //'Per favore attendi mentre aggiungiamo il prodotto al carrello.',
      allowOutsideClick: false,
    });

    axios
      .post('/v1/add-to-cart', {
        config_id: details?.configid,
        color_id: colorname,
        product_name: details?.p_name,
        product_price: price,
        suppliment: totalSupplement,
        CERTIFICATESGROUP_CGK_19052: details.CERTIFICATESGROUP_CGK_19052,
        certificate: certification,
        suppliment2: supplement2,
        quantity: QTY,
        client_id: userDataDetail.id,
        is_available_json: JSON.stringify({
          suppliment: totalSupplement,
          suppliment2: supplement2,
          available: available,
          isprecise: precise,
          isoption: isoption,
          ItemId: details?.codice_articolo,
          is_available_json: is_available_json,
        }),
        precise_integer: precise,
        avelphysical: available,
      })
      .then((response) => {
        dispatch({
          type: UPDATE_PRODUCTS_IN_CART_COUNTER,
          productsInCartCounter: response.data.result.data?.cartQuantity || 0,
        });
        modal.showSuccess({
          subtitle: t('popup.productAdded'),
          confirmButtonText: t('ok_got_it'),
          onConfirm: () => closeminicaet(),
        });
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.data?.result?.type === 'multicart_type_error') {
          handleMultiCartTypeError(userDataDetail, modal, closeminicaet, dispatch, t);
        } else {
          alert(error?.response?.data?.message || 'Something went wrong');
        }
      });
  };

  const updateQTY = (set_qty) => {
    SetQTY(parseFloat(set_qty));
  };

  useEffect(() => {
    document.getElementById('home_page_class').classList.remove('home_page');
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    var acc = document.getElementsByClassName('accordion_title_copy');
    var i;
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', function () {
        this.classList.toggle('active_accordation');
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + 'px';
        }
      });
    }
  }, [tab]);

  if (isLoading) {
    return (
      <div className="loader_wrapper">
        <div className="loader"></div>
      </div>
    );
  }

  var product_slider = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 766,
        settings: {
          dots: false,
          arrows: true,
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="main_content product_detail">
        <div className="product_section">
          <div className="product_wrappper container">
            <div className="product_title mobile_section">
              <span>{t('collezione')}</span>
              <h2>{details?.p_name}</h2>
              {/*<div className="sheet_link">*/}
              {/*  <a href="#">{t("visualizza_scheda_tecnica")}</a>*/}
              {/*</div>*/}
            </div>

            <div className="product_slider product_media">
              {!activeColor && (
                <Slider {...product_slider}>
                  {details?.product_images?.map((id) => (
                    <div key={id} className="product_slider_block">
                      <div className="product_slider_img">
                        <img
                          src={id}
                          className={`desktop_img ${isZoom ? 'zoom_image' : ''}`}
                          alt="product_image"
                          style={{ filter: `hue-rotate(${Math.floor(hexToDegrees(activeColor) || 0) + 'deg'})` }}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = '/images/artic_06.jpg';
                          }}
                        />
                        {!isZoom ? (
                          <p className="zoom" onClick={() => setIsZoom(1)}>
                            Zoom{' '}
                            <span>
                              {' '}
                              <img src="/images/search.png" />{' '}
                            </span>{' '}
                          </p>
                        ) : (
                          <p className="zoom" onClick={() => setIsZoom(0)}>
                            Zoom Out{' '}
                            <span>
                              {' '}
                              <img src="/images/search.png" />{' '}
                            </span>{' '}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                  {details?.product_cc_images?.map((id) => (
                    <div key={id} className="product_slider_block">
                      <div className="product_slider_img">
                        <img
                          src={id}
                          className={`desktop_img ${isZoom ? 'zoom_image' : ''}`}
                          alt="product_image"
                          style={{ filter: `hue-rotate(${Math.floor(hexToDegrees(activeColor)) + 'deg'})` }}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = '/images/artic_06.jpg';
                          }}
                        />
                        {!isZoom ? (
                          <p className="zoom" onClick={() => setIsZoom(1)}>
                            Zoom{' '}
                            <span>
                              {' '}
                              <img src="/images/search.png" />{' '}
                            </span>{' '}
                          </p>
                        ) : (
                          <p className="zoom" onClick={() => setIsZoom(0)}>
                            Zoom Out{' '}
                            <span>
                              {' '}
                              <img src="/images/search.png" />{' '}
                            </span>{' '}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </Slider>
              )}
              {activeColor && (
                <Slider {...product_slider}>
                  {details?.product_t_images?.map((id) => (
                    <div key={id} className="product_slider_block">
                      {activeimage == '' ? (
                        <div className="product_slider_img" style={{ backgroundColor: `${activeColor}` }}>
                          <img
                            style={{ mixBlendMode: 'soft-light' }}
                            src={id}
                            className={`desktop_img ${isZoom ? 'zoom_image' : ''}`}
                            // className={`desktop_img ${isZoom ? '' : ''}`}
                            alt="product_image"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = '/images/artic_06.jpg';
                            }}
                          />
                          {!isZoom ? (
                            <p className="zoom" onClick={() => setIsZoom(1)}>
                              Zoom{' '}
                              <span>
                                {' '}
                                <img src="/images/search.png" />{' '}
                              </span>{' '}
                            </p>
                          ) : (
                            <p className="zoom" onClick={() => setIsZoom(0)}>
                              Zoom Out{' '}
                              <span>
                                {' '}
                                <img src="/images/search.png" />{' '}
                              </span>{' '}
                            </p>
                          )}
                        </div>
                      ) : (
                        <div className="product_slider_img">
                          <img
                            style={{ mixBlendMode: 'soft-light' }}
                            src={activeimage}
                            className={`desktop_img ${isZoom ? 'zoom_image' : ''}`}
                            // className={`desktop_img ${isZoom ? '' : ''}`}
                            alt="product_image"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = '/images/artic_06.jpg';
                            }}
                          />
                          {!isZoom ? (
                            <p className="zoom" onClick={() => setIsZoom(1)}>
                              Zoom{' '}
                              <span>
                                {' '}
                                <img src="/images/search.png" />{' '}
                              </span>{' '}
                            </p>
                          ) : (
                            <p className="zoom" onClick={() => setIsZoom(0)}>
                              Zoom Out{' '}
                              <span>
                                {' '}
                                <img src="/images/search.png" />{' '}
                              </span>{' '}
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </Slider>
              )}
            </div>

            <div className="product_info">
              <div className="product_title desktop_section">
                <span>{t('collezione')}</span>
                <h2>{_.capitalize(details?.p_name)}</h2>
                {/*<div className="sheet_link">*/}
                {/*  <a href="#">{t("visualizza_scheda_tecnica")}</a>*/}
                {/*</div>*/}
              </div>
              <ProductPanel
                {...{
                  setShowRequestSample,
                  setTab,
                  details,
                  tab,
                  screenAlias,
                  handleAccordation,
                  subProduct,
                  isLoadingFilter,
                  setActivedetails,
                  openMiniCart,
                  colorname,
                  colorDisponibili,
                  setFilterColor,
                  colorList,
                  filterColor,
                  handleColorSelect,
                  activeColor,
                  cID,
                  userDataDetail,
                  setSubProduct,
                  activedetails,
                }}
              />

              <div className="order_action desktop_section">
                {userPermissions.canAddNormalProductToCart ? <p>{t('per_ordinire_il_seguente')}</p> : null}
                <div className="primary_action">
                  {userDataDetail?.id && !(agentDataDetail && agentDataDetail?.id === userDataDetail?.id) ? (
                    <div className="req_link">
                      <PrimaryButton fullWidth onClick={() => setShowRequestSample(true)}>
                        {t('richiedi_campionatura')}
                      </PrimaryButton>
                    </div>
                  ) : (
                    <Link to={getTranslated(i18n, registerPaths, { en: 'en', it: 'it' }, 'it')}>
                      {t('richiedi_campionatura')}
                    </Link>
                  )}
                </div>
                <div className="login_action">
                  {userDataDetail?.id == undefined ? (
                    <Link to={getTranslated(i18n, registerPaths, { en: 'en', it: 'it' }, 'it')}>
                      {t('Aggiungi_al_carrello')}
                    </Link>
                  ) : userPermissions.canAddNormalProductToCart ? (
                    agentDataDetail && agentDataDetail?.id === userDataDetail?.id ? (
                      <Link className="no-padding" to="/dashboard-list">
                        <PrimaryButton fullWidth>{t('agent.select-client')}</PrimaryButton>
                      </Link>
                    ) : (
                      <PrimaryButton fullWidth onClick={openMiniCart}>
                        {t('Aggiungi_al_carrello')}
                      </PrimaryButton>
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <Customerservice
            details={details}
            config_id={details?.configid}
            cID={cID}
            product_name={details?.p_name}
            client_id={userDataDetail?.id}
          />
          <div className="seller_section">
            <div className="seller_wrapper container">
              <div className="title">
                <ul>
                  <li className="active">
                    <a href="#">{t('prodotti_correlati')}</a>
                  </li>
                </ul>
              </div>

              <div className="seller_content w-100">
                <Suggestedslider details={details} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {showRequestSample ? (
        <RequestSample
          onClose={() => setShowRequestSample(false)}
          details={details}
          productsForFondo={details?.sub_produts || []}
        />
      ) : null}

      {cID >= 1 && summary >= 1 ? (
        <Drawer
          onClose={closeminicaet}
          title={t('Riepilogo')}
          content={
            <div className="product_summary">
              <div className="product_details">
                <div className="product_img_name">
                  <ProductImage
                    activeimage={activeimage}
                    activeColor={activeColor}
                    product_t_images={details?.product_t_images || []}
                    product_image={details.product_image}
                  />
                  <div>
                    <h4 className="product_name">
                      {_.capitalize(details.p_name)} {subProduct?.is_mel == 'M' ? ' mèlange' : ''}
                    </h4>
                    <p>{details.composition}</p>
                    <p>
                      {details?.fibra_1} {details?.perc_1?.replaceAll('.0', '')}
                      {details.perc_1 ? '%' : ''}
                      {details.fibra_2 ? ' - ' : ''}
                      {details?.fibra_2}{' '}
                      {details?.perc_2 != 0 ? (
                        <>
                          {details?.perc_2?.replaceAll('.0', '')}
                          {details.perc_2 ? '% ' : ''}
                        </>
                      ) : (
                        ''
                      )}
                      {details.fibra_3 ? ' - ' : ''}
                      {details?.fibra_3}{' '}
                      {details?.perc_3 != 0 ? (
                        <>
                          {details?.perc_3?.replaceAll('.0', '')}
                          {details.perc_3 ? '% ' : ''}
                        </>
                      ) : (
                        ''
                      )}
                    </p>
                    <p>
                      <span>Nm:</span>
                      <span> {details.nm}</span>
                    </p>
                    <p>
                      <span>Ne:</span>
                      <span> {details.ne}</span>
                    </p>
                    <p>
                      <span>{t('finezza')}:</span>
                      <span>{details.finezza} </span>
                    </p>
                    <p>
                      <span>{t('color')}:</span>
                      <span> {colorname}</span>
                    </p>
                  </div>
                </div>
                <div>
                  <h5 className="details_title">Definisci quantità*</h5>
                  <p>Seleziona la quantità che preferisci tra Kg e numero di rocche</p>
                </div>
                <div className="product_qty">
                  <h4>Kg</h4>
                  <QuantitySelector QTY={QTY} updateQTY={updateQTY} />
                </div>

                {userDataDetail.PRECISECONES_CGK_2014 == 1 ? (
                  <div className="request_precise">
                    <label>
                      <input onChange={(e) => Richiedi_rocche_preciseChange()} type="checkbox" />
                      <span className="checkbox" />
                    </label>
                    <div>
                      <span>Richiedi rocche precise</span>

                      {precise >= 1 ? (
                        <>
                          {/*<h5>Numero rocche</h5>
                          <div className="product_qty">
                            <p>
                              <span
                                className="minus"
                                onClick={(e) => {
                                  if (precise >= 2) Setprecise(precise - 1);
                                }}
                              >
                                -
                              </span>
                              <input
                                key={'asdadd'}
                                value={precise}
                                onChange={(e) => {
                                  e.target.value <= 99999 && e.target.value >= 1
                                    ? Setprecise(e.target.value)
                                    : Setprecise(1);
                                }}
                                type="number"
                              />
                              <span
                                className="add"
                                onClick={(e) => {
                                  Setprecise(precise + 1);
                                }}
                              >
                                +
                              </span>{' '}
                            </p>
                          </div>*/}
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <AvailabilitySelector
                globalAvailable={globalAvailable}
                QTY={QTY}
                available={available}
                Setavailable={Setavailable}
              />
              <CertificateSelector
                globalCertifications={globalCertifications}
                certification={certification}
                setCertification={setCertification}
              />
              <div className="required_fields">
                <p>*Campi obbligatori</p>
              </div>
              <div className="total_price">
                <div>
                  <p>Prezzo unitario</p>
                  <p>{formatPriceForView(unitPrice)}</p>
                </div>
                <div>
                  <p>Quantità</p>
                  <p>{formatQuantityForView(QTY)}</p>
                </div>
                {/* certificatprice will be needed in the future; DO NOT REMOVE IT */}
                {/* {certificat ? (
                  <div>
                    <p>Certificazione {certificat}</p>
                    <p>{formatPriceForView(certificatprice)}</p>
                  </div>
                ) : (
                  ''
                )} */}

                {totalSupplement ? (
                  <div>
                    <p>Supplemento </p>
                    {loadingProductTotal ? (
                      <div className="loader" style={{ width: '18px', height: '18px', borderWidth: '2px' }}></div>
                    ) : (
                      <p>{formatPriceForView(totalSupplement)}</p>
                    )}
                  </div>
                ) : (
                  ''
                )}
                <div>
                  <h4>Totale</h4>
                  {loadingProductTotal ? (
                    <div className="loader" style={{ width: '18px', height: '18px', borderWidth: '2px' }}></div>
                  ) : (
                    <h4>{formatPriceForView(totalPrice)}</h4>
                  )}
                  {/* certificatprice will be needed in the future; DO NOT REMOVE IT */}
                  {/* <h4>{formatPriceForView(price * QTY + (certificatprice + suppliment))}</h4> */}
                </div>
                <p>Le spese di spedizione saranno calcolate al check-out. </p>
              </div>
              <div className="add_cart_btn active">
                {price >= 1 && QTY >= 1 && available != 'option' ? (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      addtoCart();
                    }}
                  >
                    Aggiungi al carrello
                  </a>
                ) : (
                  quantityLoader.asJsx || (
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      href="#"
                      style={{ cursor: 'default', opacity: 0.5 }}
                    >
                      Aggiungi al carrello
                    </a>
                  )
                )}
              </div>
            </div>
          }
        />
      ) : null}
    </>
  );
};

export default Product;