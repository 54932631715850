import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import axios from 'axios';

export const useCreditSituationList = ({}) => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setIsLoading(true);

    axios
      .get('/v1/get-credit-situation', {
        headers: {
          Authorization: `Bearer ${dataUser.token}`,
        },
        signal,
      })
      .then((res) => {
        let AmountMST = 0;

        const rows = (res?.data || []).map((item) => {
          AmountMST += item?.AmountCur || 0;
          item.AmountMST = AmountMST;
          return item;
        });

        setRows(rows);
      })
      .catch((error) => {
        console.error('error', error);
        setRows([]);
      })
      .finally(() => setIsLoading(false));

    return () => abortController.abort();
  }, []);

  return {
    isLoading,
    rows,
  };
};