import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import axios from 'axios';

import { useModal } from '../../../../providers/ModalProvider';
import { useLoader } from '../../../../utils/hooks';
import PrimaryButton from '../../../Buttons/PrimaryButton/PrimaryButton';

import './styles.scss';

const SubUsersList = () => {
  const dataUser = useSelector((state) => state?.auth?.userDataDetail);

  const modal = useModal();
  const { t } = useTranslation();
  const [userData, setUserData] = useState([]);
  const utentiTableLoader = useLoader({ marginTop: '20px' });
  const createUserLoader = useLoader({ height: 40, width: 150 });

  const handleDelete = (id) => {
    modal.showQuestion({
      title: t('Are you sure?'),
      subtitle: t("You won't be able to revert this!"),
      confirmButtonText: t('si'),
      cancelButtonText: t('NO'),
      onConfirm: () => {
        utentiTableLoader.watchPromise(
          axios
            .delete(`/v1/delete-sub-users/${id}`)
            .then((res) => {
              getuserData();
            })
            .catch((error) => console.log('errorr', error))
        );
      },
    });
  };

  const getuserData = () => {
    axios
      .get('/v1/get-all-sub-users/' + dataUser.id)
      .then((res) => {
        setUserData(res?.data?.result?.data?.data);
      })
      .catch((error) => console.log('error', error));
  };

  useEffect(() => {
    getuserData();
  }, []);

  return (
    <div className="tab_content utenti sub-users-list">
      <div className="content_info">
        <div className="header">
          <div className="create-user">
            <Link to="/user-create">
              <PrimaryButton fullWidth>{t('Crea nuovo utente')}</PrimaryButton>
            </Link>
          </div>
        </div>
        <div className="content_data">
          <div className="utenti_table_header">
            <div className="utenti_table_row">
              <div className="utenti_table_title">{t('Nome')}</div>
              <div className="utenti_table_title">{t('Cognome')}</div>
              <div className="utenti_table_title">{t('Email')}</div>
              <div className="utenti_table_title">{t('Telefono')}</div>
              <div className="utenti_table_title">{t('Ruolo')}</div>
              <div className="utenti_table_title icon">{t('Visualizzazione_situazione_creditizia')}</div>
              <div className="utenti_table_title icon">{t('Creazione ordine')}</div>
              <div className="utenti_table_title icon">{t(' Visualizzazione storico ordini')}</div>
              <div className="utenti_table_title icon">{t('Download_documenti')}</div>
              <div className="utenti_table_title icon">{t('Creazione_ticket')}</div>
              <div className="utenti_table_title icon">{t('Visualizzazione_storico_ticket')}</div>
              <div className="utenti_table_title" />
            </div>
          </div>
          <div className="utenti_table_body">
            {utentiTableLoader.asJsx ||
              (userData?.length &&
                userData.map((item, index) => {
                  return (
                    <div key={index} className="utenti_table_row">
                      <div className="utenti_table_column" data-label="Nome">
                        {item?.fname}
                      </div>
                      <div className="utenti_table_column" data-label="Cognome">
                        {item?.lname}
                      </div>
                      <div className="utenti_table_column" data-label="Email">
                        {item?.email}
                      </div>
                      <div className="utenti_table_column" data-label="Telefono">
                        {item?.phone}
                      </div>
                      <div className="utenti_table_column" data-label="Ruolo">
                        {item?.role}
                      </div>
                      <div className="utenti_table_column icon" data-label="Visualizzazione catalogo">
                        <img
                          src={item?.view_credit ? 'images/utenti-true.png' : 'images/utenti-false.png'}
                          alt="Checkmark"
                        />
                      </div>
                      <div className="utenti_table_column icon" data-label="Creazione ordine">
                        <img
                          src={item?.create_order ? 'images/utenti-true.png' : 'images/utenti-false.png'}
                          alt="Checkmark"
                        />
                      </div>
                      <div className="utenti_table_column icon" data-label="Visualizzazione storico ordini">
                        <img
                          src={item?.order_history ? 'images/utenti-true.png' : 'images/utenti-false.png'}
                          alt="Checkmark"
                        />
                      </div>
                      <div className="utenti_table_column icon" data-label="Download documenti">
                        <img
                          src={item?.download_document ? 'images/utenti-true.png' : 'images/utenti-false.png'}
                          alt="Checkmark"
                        />
                      </div>
                      <div className="utenti_table_column icon" data-label="Creazione ticket">
                        <img
                          src={item?.create_ticket ? 'images/utenti-true.png' : 'images/utenti-false.png'}
                          alt="Checkmark"
                        />
                      </div>
                      <div className="utenti_table_column icon" data-label="Visualizzazione storico ticket">
                        <img
                          src={item?.ticket_history ? 'images/utenti-true.png' : 'images/utenti-false.png'}
                          alt="Checkmark"
                        />
                      </div>
                      <div className="utenti_table_column action">
                        <Link to={`/user-edit/${item?.id}`} className="action_edit">
                          <img src="/images/edit-order.png" alt="Edit" />
                        </Link>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            handleDelete(item?.id);
                          }}
                          className="action_delete cursor-pointer"
                        >
                          <img src="/images/delete-order.png" alt="delete" />
                        </Link>
                      </div>
                    </div>
                  );
                }))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubUsersList;